<template>
  <main/>
</template>

<script>
export default {
  name: 'RedirectToLoginWith',
  props: ['authProviderName'],

  mounted() {
    if(this.$root.auth) {
      this.closeOrRedirectHome()
    }

    switch (this.authProviderName) {
      case 'vk': {
        const appID = 7462225
        const apiVersion = '5.122'
        const perms = {
          offline: 65536
        }
        const permissions = String(perms.offline)
        const redirectURI = `${window.location.origin}${this.getCallbackURIFor('vk')}`

        const baseURL = 'https://oauth.vk.com/authorize'
        const urlSearchParams = new URLSearchParams()
        urlSearchParams.set('client_id', String(appID))
        urlSearchParams.set('scope', String(permissions))
        urlSearchParams.set('redirect_uri', redirectURI)
        urlSearchParams.set('response_type', 'code')
        urlSearchParams.set('v', apiVersion)
        const finalURL = `${baseURL}?${urlSearchParams.toString()}`
        window.location.replace(finalURL)
        break
      }

      default:
        this.closeOrRedirectHome()
    }
  },

  methods: {
    closeOrRedirectHome() {
      window.close()
      this.$router.replace({ name: 'home' })
    },

    getCallbackURIFor(authProvider) {
      const uri = this.$router.resolve({
        name: 'auth-callback',
        params: { authprovider: authProvider }
      }).href
      return uri
    },
  },
}
</script>

<style lang="less" scoped>

</style>
