<template>
  <main>
    <LoadingBlock/>
  </main>
</template>

<script>
import LoadingBlock from '@/components/Shared/LoadingBlock'
export default {
  name: 'AuthCallback',
  components: { LoadingBlock },
  props: ['authProviderName'],

  mounted() {
    switch(this.authProviderName) {
      case 'vk':
        this.loginWithVK()
        return

      default:
        this.closeOrRedirectHome()
    }
  },

  methods: {
    closeOrRedirectHome() {
      window.close()
      this.$router.replace({ name: 'home' })
    },

    loginWithVK() {
      this.$root.makeAPIRequest('auth', 'log_in_with', {
        provider: 'vk',
        code: this.$route.query.code,
      })
        .then((response) => {
          if(response.data.error) {
            console.error(`Ошибка загрузки данных: ${response.data.message}`)
            alert(`Ошибка: ${response.data.message}`)
            return
          }

          const auth = {
            token: response.data.result.token,
            username: response.data.result.username,
          }
          const json = JSON.stringify(auth)
          localStorage.setItem('authData', json)
          this.closeOrRedirectHome()
        })
        .catch(() => {
          alert('Ошибка соединения. Проверьте соединение с интернет')
          this.closeOrRedirectHome()
        })
    },
  }
}
</script>

<style lang="less" scoped>

</style>
