import { render, staticRenderFns } from "./RedirectToLoginWith.vue?vue&type=template&id=83accae2&scoped=true&"
import script from "./RedirectToLoginWith.vue?vue&type=script&lang=js&"
export * from "./RedirectToLoginWith.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83accae2",
  null
  
)

export default component.exports